import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function ProviderRouter() {
    const ListTypes = lazy(() => import('../../pages/providers/clinics/types/ListTypes'))
    const RegisterTypes = lazy(() => import('../../pages/providers/clinics/types/RegisterTypes'))
    const EditTypes = lazy(() => import('../../pages/providers/clinics/types/EditType'))

    const DataTableClinics = lazy(() => import('../../pages/providers/clinics/DatatableClinics'))
    const DataTableClinicsPending = lazy(() => import('../../pages/providers/clinics/DatatableClinicsPending'))

    const ProfileClinics = lazy(() => import('../../pages/providers/clinics/ProfileClinic'))
    const RegisterClinics = lazy(() => import('../../pages/providers/clinics/RegisterClinic'))
    const RegisterUnipessoalClinic = lazy(() => import('../../pages/providers/clinics/RegisterUnipessoalClinic'))
    const RegisterServiceClinics = lazy(
        () => import('../../pages/providers/clinics/RegisterServiceClinic')
    )
    const EditServiceClinics = lazy(() => import('../../pages/providers/clinics/EditServiceClinic'))

    const ListDoctors = lazy(() => import('../../pages/providers/doctors/DatatableDoctors'))
    const RegisterDoctor = lazy(() => import('../../pages/providers/doctors/RegisterDoctor'))
    const ProfileDoctors = lazy(() => import('../../pages/providers/doctors/ProfileDoctor'))

    const ListOccupation = lazy(
        () => import('../../pages/providers/doctors/occupation/ListOccupation')
    )
    const RegisterOccupation = lazy(
        () => import('../../pages/providers/doctors/occupation/RegisterOccupation')
    )
    const EditOccupation = lazy(
        () => import('../../pages/providers/doctors/occupation/EditOccupation')
    )
    const ListTypesDoctor = lazy(() => import('../../pages/providers/doctors/type/ListType'))

    const ListProducts = lazy(() => import('../../pages/providers/product/ListProducts'))
    const RegisterProducts = lazy(() => import('../../pages/providers/product/RegisterProduct'))
    const EditProducts = lazy(() => import('../../pages/providers/product/EditProduct'))
    const ListSynonyms = lazy(() => import('../../pages/providers/product/synonyms/ListSynonyms'))
    const RegisterSynonyms = lazy(
        () => import('../../pages/providers/product/synonyms/RegisterSynonyms')
    )
    const ListTypeProduct = lazy(() => import('../../pages/providers/product/type/ListType'))
    const RegisterTypeProduct = lazy(
        () => import('../../pages/providers/product/type/RegisterType')
    )
    const EditTypeProduct = lazy(() => import('../../pages/providers/product/type/EditType'))
    return (
        <Switch>
            {/* Categorias Estabelecimento */}
            <Route
                path='/prestadores/estabelecimentos/categorias/editar/:id'
                component={EditTypes}
            />
            <Route
                path='/prestadores/estabelecimentos/categorias/cadastro'
                component={RegisterTypes}
            />
            <Route path='/prestadores/estabelecimentos/categorias' component={ListTypes} />
            {/* Estabelecimentos */}
            <Route
                path='/prestadores/estabelecimentos/:idClinic/servicos/cadastro'
                component={RegisterServiceClinics}
            />
            <Route
                path='/prestadores/estabelecimentos/:idClinic/servicos/edit/:id'
                component={EditServiceClinics}
            />
            <Route path='/prestadores/estabelecimentos/unipessoal' component={RegisterUnipessoalClinic} />
            <Route path='/prestadores/estabelecimentos/cadastro' component={RegisterClinics} />
            <Route path='/prestadores/estabelecimentos/pendentes' component={DataTableClinicsPending} />
            <Route path='/prestadores/estabelecimentos/:id' component={ProfileClinics} />
            <Route path='/prestadores/estabelecimentos' component={DataTableClinics} />
            {/* Profissões */}
            <Route
                path='/prestadores/profissionais/profissoes/editar/:id'
                component={EditOccupation}
            />
            <Route
                path='/prestadores/profissionais/profissoes/cadastro'
                component={RegisterOccupation}
            />
            <Route path='/prestadores/profissionais/profissoes' component={ListOccupation} />
            <Route
                path='/prestadores/profissionais/tipo_profissionais'
                component={ListTypesDoctor}
            />
            {/* Profissionais */}
            <Route path='/prestadores/profissionais/cadastro' component={RegisterDoctor} />
            <Route path='/prestadores/profissionais/:id' component={ProfileDoctors} />

            <Route path='/prestadores/profissionais' component={ListDoctors} />
            {/* Produtos */}
            <Route
                path='/prestadores/produtos/tipo_produtos/editar/:id'
                component={EditTypeProduct}
            />
            <Route
                path='/prestadores/produtos/tipo_produtos/cadastro'
                component={RegisterTypeProduct}
            />
            <Route path='/prestadores/produtos/tipo_produtos' component={ListTypeProduct} />

            <Route path='/prestadores/produtos/sinonimos/cadastro' component={RegisterSynonyms} />
            <Route path='/prestadores/produtos/sinonimos' component={ListSynonyms} />
            <Route path='/prestadores/produtos/edit/:id' component={EditProducts} />
            <Route path='/prestadores/produtos/cadastro' component={RegisterProducts} />
            <Route path='/prestadores/produtos' component={ListProducts} />
        </Switch>
    )
}
