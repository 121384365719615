import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export default function ManagementsRouter() {
    const SpecialistsPage = lazy(() => import('../../pages/management/specialist/ListSpecialists'))
    const SpecialistsRegister = lazy(
        () => import('../../pages/management/specialist/RegisterSpecialist')
    )
    const SpecialistsEdit = lazy(() => import('../../pages/management/specialist/EditSpecialist'))

    const SubSpecialistsPage = lazy(
        () => import('../../pages/management/specialist/subSpecialist/ListSubSpecialist')
    )
    const SubSpecialistRegister = lazy(
        () => import('../../pages/management/specialist/subSpecialist/RegisterSubSpecialist')
    )
    const SubSpecialistEdit = lazy(
        () => import('../../pages/management/specialist/subSpecialist/EditSubSpecialist')
    )

    /*Rotas para Usuários*/
    const DatatableUsers = lazy(() => import('../../pages/management/users/ListUsers'))
    const StoreUser = lazy(() => import('../../pages/management/users/RegisterUser'))
    const ProfileUser = lazy(() => import('../../pages/management/users/ProfileUser'))
    const UpdateUser = lazy(() => import('../../pages/management/users/UpdateUser'))

    const ClientPage = lazy(() => import('../../pages/management/clients/DatatableClients'))
    const ClientProfile = lazy(() => import('../../pages/management/clients/ProfileClient'))
    const ClientRatingList = lazy(() => import('../../pages/management/clients/rating/ListRating'))
    const ClientPatientsPage = lazy(
        () => import('../../pages/management/clients/patients/DatatablePatient')
    )

    const PromotersPage = lazy(() => import('../../pages/management/promoters/ListPromoters'))
    const PromotersProfile = lazy(() => import('../../pages/management/promoters/ProfilePromoter'))
    const PromotersEdit = lazy(() => import('../../pages/management/promoters/EditPromoter'))
    const PromotersRegister = lazy(
        () => import('../../pages/management/promoters/RegisterPromoter')
    )
    const PromotersClients = lazy(
        () => import('../../pages/management/promoters/clients/ListClients')
    )

    const ProfilePage = lazy(() => import('../../pages/management/profiles/ListProfiles'))
    const ProfileEdit = lazy(() => import('../../pages/management/profiles/EditProfile'))
    const ProfileRegister = lazy(() => import('../../pages/management/profiles/RegisterProfile'))

    const DatatableBanks = lazy(() => import('../../pages/management/banks/DatatableBanks'))

    return (
        <Switch>
            <Route path='/gestao/especialidades/editar/:id' component={SpecialistsEdit} />
            <Route path='/gestao/especialidades/cadastro' component={SpecialistsRegister} />
            <Route path='/gestao/especialidades' component={SpecialistsPage} />

            <Route path='/gestao/subespecialidades/editar/:id' component={SubSpecialistEdit} />
            <Route path='/gestao/subespecialidades/cadastro' component={SubSpecialistRegister} />
            <Route path='/gestao/subespecialidades' component={SubSpecialistsPage} />

            {/*Rotas para Usuários*/}
            <Route path='/gestao/usuarios/cadastro' component={StoreUser} />
            <Route path='/gestao/usuarios/editar/:id' component={UpdateUser} />
            <Route path='/gestao/usuarios/:id' component={ProfileUser} />
            <Route path='/gestao/usuarios' component={DatatableUsers} />

            <Route path='/gestao/clientes/pacientes' component={ClientPatientsPage} />
            <Route path='/gestao/clientes/avaliacoes' component={ClientRatingList} />
            <Route path='/gestao/promoters/editar/:id' component={PromotersEdit} />
            <Route path='/gestao/clientes/:id' component={ClientProfile} />
            <Route path='/gestao/clientes' component={ClientPage} />

            <Route path='/gestao/promoters/clientes' component={PromotersClients} />
            <Route path='/gestao/promoters/cadastro' component={PromotersRegister} />
            <Route path='/gestao/promoters/editar/:id' component={PromotersEdit} />
            <Route path='/gestao/promoters/:id' component={PromotersProfile} />
            <Route path='/gestao/promoters' component={PromotersPage} />

            <Route path='/gestao/perfis/cadastro' component={ProfileRegister} />
            <Route path='/gestao/perfis/editar/:id' component={ProfileEdit} />
            <Route path='/gestao/perfis' component={ProfilePage} />

            <Route path='/gestao/bancos' component={DatatableBanks} />
        </Switch>
    )
}
